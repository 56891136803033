import { render } from 'caroucssel/utils/render';

const requestAnimationFrame = (callback) => {
  const rAF = window.requestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame;
  return rAF?.(callback);
};

const cancelAnimationFrame = (id) => {
  const cAF = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
  return cAF?.(id);
};

const template = ({ controls }) => `
  <div
    class="carousel__scrollbar"
    role="scrollbar"
    aria-controls="${controls}"
    aria-orientation="horizontal"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div class="carousel__scrollbar-thumb" />
  </div>
`;

export class Scrollbar {
  constructor() {
    this.onScroll = this.onScroll.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  get name() {
    return 'yvesrijckaert:scrollbar';
  }

  init(carousel) {
    const { el, mask } = carousel;
    const container = (mask ?? el).parentNode;
    const scrollbar = render(template, { controls: el.id });
    container.appendChild(scrollbar);

    this.carousel = carousel;
    this.scrollbar = scrollbar;
    this.thumb = scrollbar.firstElementChild;

    el.addEventListener('scroll', this.onScroll, { passive: true });
    scrollbar.addEventListener('mousedown', this.onStart, { passive: true });

    this.onResize();
    this.onScroll();
  }

  destroy() {
    const {
      carousel: { el },
      scrollbar,
    } = this;

    scrollbar.parentElement.removeChild(scrollbar);

    el.removeEventListener('scroll', this.onScroll);
    scrollbar.removeEventListener('mousedown', this.onStart);
    scrollbar.removeEventListener('touchstart', this.onStart);

    cancelAnimationFrame(this.resizeRAF);
    cancelAnimationFrame(this.scrollRAF);

    this.carousel = null;
    this.scrollbar = null;
    this.thumb = null;
    this.resizeRAF = undefined;
    this.scrollRAF = undefined;
  }

  update(event) {
    if (event.type === 'resize') {
      this.onResize();
    }
  }

  onResize() {
    cancelAnimationFrame(this.resizeRAF);

    this.resizeRAF = requestAnimationFrame(() => {
      const { el } = this.carousel;

      // Use the thumb width in the same ratio like the carousel's viewport and
      // the content width.
      const ratio = el.offsetWidth / el.scrollWidth;
      this.thumb.style.width = `${ratio * 100}%`;
    });
  }

  onScroll() {
    cancelAnimationFrame(this.scrollRAF);

    this.scrollRAF = requestAnimationFrame(() => {
      const { carousel, scrollbar, thumb } = this;
      const { el } = carousel;

      // Calculate the left position of the scrollbar thumb based on relative
      // scroll position. Since the ratio between thumb and scrollbar is equal to
      // the ratio of the carousel's viewport and content width, the left position of
      // the thumb uses the same ratio as the left scroll position.
      const ratio = el.scrollLeft / el.scrollWidth;
      const length = scrollbar.clientWidth;
      thumb.style.transform = `translate3d(${ratio * length}px, 0, 0)`;
    });
  }

  onStart(event) {
    const { carousel, thumb } = this;
    const { el } = carousel;

    this.initialLeftRatio = el.scrollLeft / (el.scrollWidth - el.offsetWidth);
    this.initialClientX = event.clientX ?? event.touches[0].clientX;

    // To receive a flicker-free scroll and drag animation we need to set the
    // scroll-behavior to 'auto'. Otherwise a scroll-behavior of 'smooth' will
    // trigger multiple onScroll events that do not meet the visual update onDrag.
    el.style.scrollBehavior = 'auto';
    thumb.style.cursor = 'grabbing';
    thumb.style.userSelect = 'none';

    window.addEventListener('mousemove', this.onMove, { passive: true });
    window.addEventListener('touchmove', this.onMove, { passive: true });
    window.addEventListener('mouseup', this.onEnd, { passive: true });
    window.addEventListener('touchend', this.onEnd, { passive: true });
  }

  onMove(event) {
    const { carousel, scrollbar, thumb } = this;
    const { el } = carousel;

    // Calculate the relative distance the user dragged the thumb since he
    // started to drag.
    const clientX = event.clientX ?? event.touches[0].clientX;
    const distance = clientX - this.initialClientX;
    const relative = distance / (scrollbar.clientWidth - thumb.clientWidth);

    // Calculate the new scroll left based ratio change and the available
    // scrollable space.
    let ratio = this.initialLeftRatio + relative;
    ratio = Math.min(ratio, 1);
    ratio = Math.max(ratio, 0);
    const scrollLeft = ratio * (el.scrollWidth - el.offsetWidth);

    // Setting this, implicitly triggers the onScroll event. This will then
    // update the visual thumb position.
    el.scrollLeft = scrollLeft;
  }

  onEnd() {
    const { carousel, thumb } = this;
    const { el } = carousel;

    el.style.removeProperty('scroll-behavior');
    thumb.style.removeProperty('cursor');
    thumb.style.removeProperty('user-select');

    this.initialLeftRatio = undefined;
    this.initialClientX = undefined;

    window.removeEventListener('mousemove', this.onMove);
    window.removeEventListener('touchmove', this.onMove);
    window.removeEventListener('mouseup', this.onEnd);
    window.removeEventListener('touchend', this.onEnd);
  }
}
